import moment from 'moment'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }
    return this.$path(path, data)
  }

  function getParentPost(source) {
    return (get('posts', 'Root.item') || []).find(p => p.slug === source)
  }

  function getLastAdditionalSectionTheme() {
    if (
      get('customFields.additional-sections-1.0.content') ||
      get('customFields.additional-sections-1.0.images.0')
    )
      return (get('customFields.additional-sections-1') || []).pop()?.theme || 'none'
    else if (get('customFields.icons.0.icon'))
      return get('customFields.icons-settings.theme') || 'none'

    return (get(`customFields.additional-sections`) || []).pop()?.theme || 'none'
  }

  // get next article
  const articles = (get('posts', 'Root.item') || [])
    .filter(post => post.customFieldsConfig?.[0]?.source === 'article')
    .sort(
      (a, b) =>
        a?.customFields?.['site-settings']?.order - b?.customFields?.['site-settings']?.order,
    )

  const indexOfPost = articles.findIndex(a => a.slug === this.$path('_props.slug', this))

  const next = indexOfPost === articles.length - 1 ? 0 : indexOfPost + 1
  const nextArticle = articles[next]

  return [
    {
      component: 'Section',
      condition: getParentPost(this.$path('_props.postSlug', this)),
      props: {
        width: 'medium',
        style: 'padding-bottom:0; padding-top:1rem;',
        class: 'section-breadcrumbs-top',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              gap: '0rem',
              class: 'article__breadcrumbs',
              alignItems: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Link',
                  props: {
                    target: '_self',
                    label: getParentPost(this.$path('_props.postSlug', this)).title,
                    class: 'article__breadcrumbs--link',
                  },
                  data: {
                    href: `/${getParentPost(this.$path('_props.postSlug', this)).slug}`,
                  },
                },
                {
                  component: 'Text',

                  data: {
                    content: get('title'),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      condition: get('content'),
      component: 'Section',
      props: {
        width: 'small',
        style: 'padding-bottom:0; padding-top: 2rem;',
        class: 'section-content',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              gap: '1.5rem',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  data: {
                    content: moment(get('publishedFrom')).format('DD. MMMM YYYY'),
                  },
                  props: {
                    class: 'article__date',
                  },
                },
                {
                  component: 'Text',
                  data: {
                    content: `<h1>${get('title')}</h1>${get('content')}`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'medium',
        style: 'padding-bottom:0;',
        class: 'section-article-hero',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
        },
      }
    }),
    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('customFields.icons-settings.theme'),
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('customFields.icons-settings.border-theme'),

              placement: get('customFields.icons-settings.placement'),
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant'),
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
        },
      }
    }),
    {
      component: 'Section',
      condition: nextArticle,
      props: {
        width: 'medium',
        class:
          'section-breadcrumbs-bottom section-breadcrumbs-bottom__additional-section--theme-' +
          getLastAdditionalSectionTheme(),
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              class: 'section-breadcrumbs-bottom__flex',
              alignContent: 'center',
              justifyContent: 'flex-end',
            },
            blocks: {
              default: {
                component: 'Link',
                props: {
                  target: '_self',
                  class: 'link-to-article',
                },
                data: {
                  href: nextArticle?.slug,
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      data: {
                        content: `<span>${nextArticle?.title}</span><svg
                      style="margin-left: 1rem;"
                      width="11"
                      height="21"
                      
                      viewBox="0 0 11 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.125 19.25L9.875 10.5L1.125 1.75"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('customFields.contact-cta.content'),
                  data: {
                    content: get('customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('customFields.contact-cta.button'),
                  props: {
                    class: 'contact-cta-bottom__button',
                  },
                  data: {
                    label: get('customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'secondary',
                    tag: 'router-link',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
